<template>
    <Button label="Kembali" icon="pi pi-angle-left" class="p-button-link p-button-rounded p-button-success mb-2" @click="this.$router.go(-1)"/>
    <div class="grid fadein animation-duration-300">
		<div class="col-12">
			<div class="card">
                <div class="flex flex-wrap justify-content-between flex-row grid">
                    <div class="flex justify-content-start col">
                        <h5 class="mt-auto mb-auto">Detail User</h5>
                    </div>
                </div>
                <Divider></Divider>
                <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Nama</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ this.$store.state.user.nama }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Email</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ this.$store.state.user.email }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Role</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ this.$store.state.user.role }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Status</div>
                        <div class="text-900 text-lg w-full md:w-8 md:flex-order-0 flex-order-1">{{ this.$store.state.user.status }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Detail User',
    }
</script>